import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" Programmes "),_c(VSpacer),(
        !_vm.isNew &&
        (_vm.formData.submittedBy === _vm.user.number || _vm.formData.submittedBy === '')
      )?_c(VBtn,{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.addNewLine}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" add programme ")],1):_vm._e()],1),_c(VDataTable,{attrs:{"headers":_vm.trainingLinesHeaders,"items":_vm.trainingLinesList,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.trainingDetails",fn:function(ref){
      var item = ref.item;
return [(_vm.displayConditionally(item))?_c(VTextField,{staticClass:"mb-n3",attrs:{"label":"Description","dense":"","single-line":""},on:{"focusout":_vm.autosaveTrainingLine},model:{value:(_vm.line.trainingDetails),callback:function ($$v) {_vm.$set(_vm.line, "trainingDetails", $$v)},expression:"line.trainingDetails"}}):_c('span',[_vm._v(_vm._s(item.trainingDetails))])]}},{key:"item.startDate",fn:function(ref){
      var item = ref.item;
return [(_vm.displayConditionally(item))?_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Start date","single-line":"","rules":_vm.rules.required},on:{"input":_vm.autosaveTrainingLine},model:{value:(_vm.line.startDate),callback:function ($$v) {_vm.$set(_vm.line, "startDate", $$v)},expression:"line.startDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"min":_vm.startDate},on:{"change":_vm.save,"click:date":_vm.autosaveTrainingLine},model:{value:(_vm.line.startDate),callback:function ($$v) {_vm.$set(_vm.line, "startDate", $$v)},expression:"line.startDate"}})],1):_c('span',[_vm._v(_vm._s(item.startDate))])]}},{key:"item.startTime",fn:function(ref){
      var item = ref.item;
return [(_vm.displayConditionally(item))?_c(VMenu,{ref:"menu3",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Start time","single-line":"","disabled":!_vm.line.startDate,"rules":_vm.rules.required},on:{"input":_vm.autosaveTrainingLine},model:{value:(_vm.line.startTime),callback:function ($$v) {_vm.$set(_vm.line, "startTime", $$v)},expression:"line.startTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c(VTimePicker,{attrs:{"min":_vm.line.startDate === _vm.startDate ? _vm.startTime : null,"format":"24hr","allowed-minutes":_vm.allowedStep,"use-seconds":""},on:{"click:second":function($event){return _vm.$refs.menu3.save(_vm.time)},"click:hour":_vm.autosaveTrainingLine,"click:minute":_vm.autosaveTrainingLine},model:{value:(_vm.line.startTime),callback:function ($$v) {_vm.$set(_vm.line, "startTime", $$v)},expression:"line.startTime"}})],1):_c('span',[_vm._v(_vm._s(item.startTime))])]}},{key:"item.endDate",fn:function(ref){
      var item = ref.item;
return [(_vm.displayConditionally(item))?_c(VMenu,{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"disabled":!_vm.line.startDate,"rules":_vm.rules.required,"label":"End date","single-line":""},on:{"input":_vm.autosaveTrainingLine},model:{value:(_vm.line.endDate),callback:function ($$v) {_vm.$set(_vm.line, "endDate", $$v)},expression:"line.endDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c(VDatePicker,{attrs:{"min":_vm.line.startDate},on:{"change":_vm.save2,"click:date":_vm.autosaveTrainingLine},model:{value:(_vm.line.endDate),callback:function ($$v) {_vm.$set(_vm.line, "endDate", $$v)},expression:"line.endDate"}})],1):_c('span',[_vm._v(_vm._s(item.endDate))])]}},{key:"item.endTime",fn:function(ref){
      var item = ref.item;
return [(_vm.displayConditionally(item))?_c(VMenu,{ref:"menu4",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"End time","single-line":"","disabled":!_vm.line.endDate,"rules":_vm.rules.required},on:{"input":_vm.autosaveTrainingLine},model:{value:(_vm.line.endTime),callback:function ($$v) {_vm.$set(_vm.line, "endTime", $$v)},expression:"line.endTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[_c(VTimePicker,{attrs:{"min":_vm.line.endDate === _vm.line.startDate ? _vm.line.startTime : null,"format":"24hr","allowed-minutes":_vm.allowedStep,"use-seconds":""},on:{"click:second":function($event){return _vm.$refs.menu4.save(_vm.time)},"click:hour":_vm.autosaveTrainingLine,"click:minute":_vm.autosaveTrainingLine},model:{value:(_vm.line.endTime),callback:function ($$v) {_vm.$set(_vm.line, "endTime", $$v)},expression:"line.endTime"}})],1):_c('span',[_vm._v(_vm._s(item.endTime))])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',[(_vm.displayConditionally(item))?_c('div',[_c(VIcon,{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1):_c('div',[_c(VIcon,{staticClass:"mr-3",attrs:{"color":"green"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c(VIcon,{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }